<template>
  <v-select
    :id="id"
    placeholder="Select"
    :value="value"
    :items="items"
    :rules="applyRules"
    :error="isNotValid"
    :disabled="!allowUserToEdit"
    @input="onChange($event)"
    @change="onChange($event)"
  >
    <span
      slot="label"
      v-html="label"
    />
    <span>{{ appendText }}</span>
  </v-select>
</template>

<script>
import validateInputMixin from "@/mixins/validateInputMixin";

export default {
  name: "Dropdown",

  mixins: [validateInputMixin],

  props: {
    allowUserToEdit: { type: Boolean, required: false, default: true },
    items: { type: Array, required: true, default: () => [] },
    fieldKey: {
      type: String,
      default: "",
    },
    appendText: { type: String, default: "" },
  },
  mounted() {},
  methods: {
    onChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style>
.v-list-item__content,
.v-select__selection {
  text-transform: capitalize !important;
}
</style>
